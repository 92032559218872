import * as React from 'react';
import {
    getSessionQr,
    isLoggedIn,
    loginQr,
    logout,
    setLoginCookies,
    testLogin,
    testLoginDelay
} from 'services/authentication/authentication-service';
import {RouteComponentProps, withRouter} from 'react-router';
import {
    AuthenticationState,
    BankIdSession,
    BankIdStart,
    BankIdStatus,
    User
} from 'services/authentication/types';
import {IsMobile, sleep} from 'services/utils/helpers';
import {ENV, getCurrentEnv} from 'services/constants';


export interface AuthenticationQrContextType {
    user: User,
    loggedIn: boolean;
    loginStarted: boolean;
    autoStartBankId: boolean;
    authenticationState: AuthenticationState;
    qrCode: string;
    isMobile: boolean;
    setLoggedIn: (source: string, autoStartBankId: boolean) => any;
    setLogout: () => any;
    cancelLogin: () => any;
    errorMessage: string;
}

interface Props extends RouteComponentProps {
    children: any;
}

// @ts-ignore
export const AuthenticationQrContext = React.createContext<AuthenticationQrContextType>();
export const AuthenticationQrConsumer = AuthenticationQrContext.Consumer;

export class AuthenticationQrProviderComp extends React.PureComponent<Props, AuthenticationQrContextType> {

    async componentDidMount() {

        if(window.location.href.indexOf('poolingId') > -1) {
            try {
                let poolingId = window.location.search.replace('?poolingId=', '');
    
                let i = 0;
    
                while (i < 30) {
                    i++;
                    await sleep(1000);
                    let poolingResponse = await getSessionQr(poolingId);
    
                    if(poolingResponse.authorized) {
                        this.handleBankIdSession(poolingResponse);
                        break;
                    }
                }
            } catch (e) {

            }
        }
    }

    cancelLogin = async () => {
        this.setState({
            user: null,
            authenticationState: AuthenticationState.IDLE,
            loggedIn: false,
            loginStarted: false
        })
    };

    setLoggedIn = async (source: string, autoStartBankId: boolean) => {

        let env = getCurrentEnv();

        let useTestLogin = false;
        let isTestingEnvironment = env === ENV.LOCAL || env === ENV.DEV || env === ENV.TEST;

        this.setState({
            loginStarted: true,
            autoStartBankId: autoStartBankId,
        });

        let loginResult : BankIdStart = (env === ENV.PROD || !useTestLogin) ? await loginQr() : await testLoginDelay();

        if(isTestingEnvironment || !useTestLogin || (loginResult !== null && loginResult.sessionId)) {

            if(this.state.isMobile && autoStartBankId) {
                //window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);

                let poolingUrl = window.location.origin + window.location.pathname + `?poolingId=${loginResult.sessionId}`

                window.history.replaceState({}, document.title, poolingUrl);

                window.location.href = `https://app.bankid.com/?autostarttoken=${loginResult.autoStartToken}&redirect=${encodeURIComponent(poolingUrl)}`; //&redirect=${redirectUrl}
                
                return;
            }

            let statusResponse: BankIdSession;

            this.setState({
                authenticationState: AuthenticationState.POOLING,
                qrCode: loginResult.qrCode
            }, async () => {

                let i = 0;

                while(i < 300) {
                    i++;

                    try{
                        await sleep(2000);

                        statusResponse = isTestingEnvironment && useTestLogin ? await testLogin("") : await getSessionQr(loginResult.sessionId);
    
                        this.setState({
                            qrCode: statusResponse.qrCode
                        });
    
                        if(statusResponse.authorized) {
                            break;
                        }
                    }
                    catch{
                        console.log("retry");
                    }

                }

                this.handleBankIdSession(statusResponse);
            });
        }
    };

    handleBankIdSession = (bankIdSession:BankIdSession) => {
        if(bankIdSession.status === BankIdStatus.Ok && bankIdSession.token) {

            setLoginCookies(bankIdSession.token);

            this.setState({
                user: null,
                loggedIn: true,
                authenticationState: AuthenticationState.IDLE,
                loginStarted: false,
            }, () => {
                this.props.history.push('/');
            });
        }

        if(bankIdSession.status === BankIdStatus.Error || !bankIdSession.token) {
            this.setState({
                authenticationState: AuthenticationState.IDLE,
                loggedIn: false,
                loginStarted: false,
                errorMessage: 'Ingen användare'
            })
        }
    }

    setLogout = () => {
        this.setState({
            user: null,
            loggedIn: false,
        }, () => {
            logout();
        });
    };

    state = {
        user: null,
        loginStarted: false,
        autoStartBankId: false,
        loggedIn: isLoggedIn(),
        setLoggedIn:  this.setLoggedIn,
        setLogout: this.setLogout,
        cancelLogin: this.cancelLogin,
        errorMessage: '',
        authenticationState: AuthenticationState.IDLE,
        qrCode: null,
        isMobile: IsMobile(),
    };

    render() {

        return (
            <AuthenticationQrContext.Provider value={this.state}>
                {this.props.children}
            </AuthenticationQrContext.Provider>
        );
    }

}

export const AuthenticationQrProvider = withRouter(AuthenticationQrProviderComp);
