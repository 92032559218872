/*
export const isUndefined = (value: any) => {
    return typeof value === 'undefined';
}
*/

import UAParser from 'ua-parser-js';
import {isUndefined} from 'util';

export const isEmpty = (obj: Object) => {

    if(typeof obj === 'undefined' || obj === null) return true;

    return  Object.keys(obj).length === 0;
};

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000';

export const numberToCurrency = (value?: string | number) => {
    if(value === 0) return 0;
    if(!value) return 0;

    let n = value.toString();
    let p = n.indexOf('.');
    let n2 = n.replace(/ /g,'');

    return n2.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, ($0, i) => p<0 || i<p ? ($0+' ') : $0 );
};
export const currencyToNumber = (value: string) => parseInt(value.replace(/ /g,''), 9);

export const isDefined = (value: any) => {
    return typeof value !== 'undefined';
};

export const isObject = (value: any) => {
    return value !== null && typeof value === 'object';
};

export const isBlankObject = (value: any) => {
    return value !== null && typeof value === 'object' && !Object.getPrototypeOf(value);
};

export const isFunction = (value: any) => typeof value === 'function';
export const isBoolean = (value: any) =>  typeof value === 'boolean';

// Number helpers
export const isNumber = (value: any ) => typeof value === 'number';
export const toInt = (str: string) => parseInt(str, 10);
export const isNumberNaN = Number.isNaN || function isNumberNaN(num) {
    return num !== num;
};

// Array helpers
export const isArray = (arr: any) => Array.isArray(arr) || arr instanceof Array;

export const arrayRemove = (array: any[], value: any) => {
    var index = array.indexOf(value);
    if (index >= 0) {
      array.splice(index, 1);
    }
    return index;
};

export const range = (start: number, end: number) => {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
};
  

// String helpers
export const isString = (value: any) => typeof value === 'string'; 
export const lowercase = (string: string) => isString(string) ? string.toLowerCase() : string;
export const uppercase = (string: string) => isString(string) ? string.toUpperCase() : string;

export const capitalizeFirstLetter = (string: string) => {

    if(isUndefined(string) || string === null) return '';

    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllWords = (string: string) => {

    return string.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const replaceSwedishLetters = (string: string) => {
    return string.replace(/å/g, 'a')
        .replace(/Å/g, 'a')
        .replace(/ä/g, 'a')
        .replace(/Ä/g, 'a')
        .replace(/ö/g, 'o')
        .replace(/Ö/g, 'o');
};

export const deepClone = (object: any) => {
    return JSON.parse(JSON.stringify(object));
};

export const IsMobile = () => {
    let uaParser = new UAParser(window.navigator.userAgent);
    let os = uaParser.getOS();
    let device = uaParser.getDevice();

    if (window.innerWidth <= 625) return true;

    // Ipad haxx
    if (os && os.name === 'iOS') return true;
    if (device && device.type === 'tablet') return true;
    if (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) return true;

    return (uaParser.getResult() && uaParser.getResult().device.type === 'mobile'); //|| (uaParser.getResult() && uaParser.getResult().device.type === 'tablet');
};